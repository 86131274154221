import Iframe from "react-iframe";

import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={logo}
          width="100px"
          height="100px"
          className="App-logo"
          alt="logo"
        />
        <p>
          YOU'RE ON <strong>ENV1.CXSANDBOX.COM</strong>
        </p>
        <div style={{ backgroundColor: "blue", padding: "10px" }}>
          <p>iframe starts here:</p>
          <Iframe
            url="https://env2.cxsandbox.com"
            width="800"
            height="600"
            id=""
            className=""
            display="block"
            position="relative"
          />
        </div>
      </header>
    </div>
  );
}

export default App;
